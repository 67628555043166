// src/pages/Services.js
import React from 'react';
import { Container, Typography, Grid, Card, CardContent, CardMedia, CardActions, Button } from '@mui/material';
import { styled } from '@mui/system';

const CardMediaStyled = styled(CardMedia)({
  height: 140,
});

const CardStyled = styled(Card)({
  transition: 'transform 0.2s',
  '&:hover': {
    transform: 'scale(1.05)',
  },
});

const services = [
  { title: 'Automatización Industrial', description: 'Soluciones avanzadas para la automatización de procesos industriales, aumentando la eficiencia y reduciendo costos.', image: '/img/service1.jpg' },
  { title: 'Telecomunicaciones', description: 'Servicios de telecomunicaciones de alta calidad para empresas y particulares, asegurando conectividad constante y segura.', image: '/img/service2.jpg' },
  { title: 'Tratamiento de Aguas', description: 'Sistemas eficientes para el tratamiento y gestión de aguas, garantizando agua potable y segura.', image: '/img/service3.jpg' },
  { title: 'Integración de Sistemas', description: 'Soluciones integrales para la integración de sistemas, permitiendo la interoperabilidad entre diferentes plataformas y tecnologías.', image: '/img/service4.jpg' },
  { title: 'Consultoría en Ingeniería', description: 'Servicios de consultoría en ingeniería para optimizar procesos y diseñar soluciones a medida.', image: '/img/service5.jpg' },
  { title: 'Mantenimiento Predictivo', description: 'Tecnologías y servicios para el mantenimiento predictivo, evitando fallos y aumentando la vida útil de los equipos.', image: '/img/service6.jpg' },
  { title: 'Control de Calidad', description: 'Sistemas de control de calidad para asegurar que los productos cumplan con los estándares internacionales.', image: '/img/service7.jpg' },
  { title: 'Energías Renovables', description: 'Soluciones en energías renovables para un futuro sostenible y eficiente.', image: '/img/service8.jpg' },
];

const Services = () => {
  return (
    <Container>
      <Grid container spacing={4} justifyContent="center" sx={{ mt: 5, mb: 5 }}>
        <Grid item xs={12}>
          <Typography variant="h4" align="center" gutterBottom>
            Nuestros Servicios
          </Typography>
        </Grid>
        {services.map((service, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <CardStyled>
              <CardMediaStyled
                image={service.image}
                title={service.title}
              />
              <CardContent>
                <Typography variant="h5" component="div">
                  {service.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {service.description}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" color="primary">
                  Ver más
                </Button>
              </CardActions>
            </CardStyled>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Services;
