// src/pages/Projects.js
import React, { useState } from 'react';
import { Container, Typography, Grid, Card, CardContent, CardMedia, CardActions, Button } from '@mui/material';
import ProjectModal from '../components/ProjectModal';

const projects = [
  { title: 'Proyecto Minería', description: 'Descripción completa del proyecto de minería...', image: '/img/mineria.jpg', images: ['/img/mineria.jpg', '/img/mineria2.jpg'] },
  { title: 'Proyecto Aguas Servidas', description: 'Descripción completa del proyecto de aguas servidas...', image: '/img/aguas_serv.jpg', images: ['/img/aguas_serv.jpg', '/img/aguas_serv2.jpg'] },
  { title: 'Proyecto Telecomunicaciones', description: 'Descripción completa del proyecto de telecomunicaciones...', image: '/img/telecom.jpg', images: ['/img/telecom.jpg', '/img/telecom2.jpg'] },
  { title: 'Proyecto Energía Solar', description: 'Descripción completa del proyecto de energía solar...', image: '/img/solar.jpg', images: ['/img/solar.jpg', '/img/solar2.jpg'] },
  { title: 'Proyecto Automatización Industrial', description: 'Descripción completa del proyecto de automatización industrial...', image: '/img/automatizacion.jpg', images: ['/img/automatizacion.jpg', '/img/automatizacion2.jpg'] },
  { title: 'Proyecto Planta de Tratamiento', description: 'Descripción completa del proyecto de planta de tratamiento...', image: '/img/tratamiento.jpg', images: ['/img/tratamiento.jpg', '/img/tratamiento2.jpg'] },
  { title: 'Proyecto IoT', description: 'Descripción completa del proyecto de IoT...', image: '/img/iot.jpg', images: ['/img/iot.jpg', '/img/iot2.jpg'] },
  { title: 'Proyecto Redes Eléctricas', description: 'Descripción completa del proyecto de redes eléctricas...', image: '/img/redes.jpg', images: ['/img/redes.jpg', '/img/redes2.jpg'] },
];

const Projects = () => {
  const [selectedProject, setSelectedProject] = useState(null);

  const handleCardClick = (project) => {
    setSelectedProject(project);
  };

  const handleClose = () => {
    setSelectedProject(null);
  };

  return (
    <Container>
      <Grid container spacing={4} justifyContent="center" sx={{ mt: 5, mb: 5 }}>
        <Grid item xs={12}>
          <Typography variant="h4" align="center" gutterBottom>
            Proyectos
          </Typography>
        </Grid>
        {projects.map((project, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card onClick={() => handleCardClick(project)} style={{ cursor: 'pointer' }}>
              <CardMedia
                component="img"
                alt={project.title}
                height="140"
                image={project.image}
                title={project.title}
              />
              <CardContent>
                <Typography variant="h5" component="div">
                  {project.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {project.description.slice(0, 50)}...
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" color="primary" onClick={() => handleCardClick(project)}>
                  Ver Más
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      {selectedProject && <ProjectModal project={selectedProject} onClose={handleClose} />}
    </Container>
  );
};

export default Projects;
