// src/components/ImageCarousel.js
import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { Paper } from '@mui/material';

const ImageCarousel = ({ images }) => {
  return (
    <Carousel
      navButtonsAlwaysVisible
      autoPlay={true}
      interval={3000}
      indicators={true}
      cycleNavigation={true}
      animation="slide"
    >
      {images.map((image, index) => (
        <Paper key={index} style={{ position: 'relative', height: 400 }}>
          <img
            src={image}
            alt={`carousel-${index}`}
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        </Paper>
      ))}
    </Carousel>
  );
};

export default ImageCarousel;
