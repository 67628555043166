// src/pages/Home.js
import React from 'react';
import { Container, Typography, Grid, Button, Box } from '@mui/material';
import ImageCarousel from '../components/ImageCarousel';

const Home = () => {
  const images = [
    '/img/antenas1.jpg',
    '/img/apr1.jpg',
    '/img/apr2.jpg',
  ];

  return (
    <Container>
      <Grid container spacing={4} justifyContent="center" alignItems="center" direction="column" sx={{ mt: 5, mb: 5 }}>
        <Grid item xs={12}>
          <Typography variant="h3" align="center" gutterBottom>
            Bienvenido a Caise
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" align="center" color="textSecondary" paragraph>
            Control y Automatización de Sistemas Electrónicos
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" align="center" paragraph>
            ¿Quiénes somos?
            Somos una empresa dedicada principalmente al Control y Automatización de Sistemas Electrónicos (CAISE). Incorporamos tecnologías de punta que surgen para ayudar a la humanidad y optimizar nuestros procesos productivos. Todos nuestros servicios cumplen con estándares y normas de calidad internacionales, y son llevados a cabo por expertos en el área.
            Nuestra misión es proporcionar a nuestros clientes un mayor control sobre sus procesos, ya sean productivos, sanitarios, de telecomunicaciones o cualquier proceso que implique automatización y tecnología. Aplicamos métodos avanzados de recopilación de datos para optimizar el funcionamiento de los sistemas y detectar posibles fallos o comportamientos anormales de manera temprana.
            Además, ofrecemos a nuestros clientes visualizaciones gráficas claras del control de sus sistemas y datos de procesos, ya sea de forma general o específica según los componentes del proceso, adaptándonos a sus necesidades.
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ width: '100%' }}>
          <ImageCarousel images={images} />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" href="/services" sx={{ mt: 4 }}>
            Nuestros Servicios
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Home;
